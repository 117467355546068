@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .gradient-0 {
    @apply bg-gradient-to-br from-[#5B8DEF] to-[#0063F7];
  }

  .gradient-1 {
    @apply bg-gradient-to-br from-[#FF8800] to-[#E63535];
  }

  .gradient-2 {
    @apply bg-gradient-to-br from-[#3E7BFA] to-[#6600CC];
  }

  .gradient-3 {
    @apply bg-gradient-to-br from-[#00CFDE] to-[#05A660];
  }

  .gradient-4 {
    @apply bg-gradient-to-br from-[#FF3B3B] to-[#6600CC];
  }

  .gradient-5 {
    @apply bg-gradient-to-br from-[#FDDD48] to-[#00B7C4];
  }

  .gradient-6 {
    @apply bg-gradient-to-br from-[#FF3B3B] to-[#6600CC];
  }

  .gradient-7 {
    @apply bg-gradient-to-br from-[#73DFE7] to-[#0063F7];
  }

  .gradient-8 {
    @apply bg-gradient-to-br from-[#AC5DD9] to-[#004FC4];
  }

  .gradient-9 {
    @apply bg-gradient-to-br from-[#5B8DEF] to-[#0063F7];
  }

  .hover-gradient-0 {
    @apply hover:bg-gradient-to-br hover:from-[#5B8DEF] hover:to-[#0063F7];
  }

  .hover-gradient-1 {
    @apply hover:bg-gradient-to-br hover:from-[#FF8800] hover:to-[#E63535];
  }

  .hover-gradient-2 {
    @apply hover:bg-gradient-to-br hover:from-[#3E7BFA] hover:to-[#6600CC];
  }

  .hover-gradient-3 {
    @apply hover:bg-gradient-to-br hover:from-[#00CFDE] hover:to-[#05A660];
  }

  .hover-gradient-4 {
    @apply hover:bg-gradient-to-br hover:from-[#FF3B3B] hover:to-[#6600CC];
  }

  .hover-gradient-5 {
    @apply hover:bg-gradient-to-br hover:from-[#FDDD48] hover:to-[#00B7C4];
  }

  .hover-gradient-6 {
    @apply hover:bg-gradient-to-br hover:from-[#FF3B3B] hover:to-[#6600CC];
  }

  .hover-gradient-7 {
    @apply hover:bg-gradient-to-br hover:from-[#73DFE7] hover:to-[#0063F7];
  }

  .hover-gradient-8 {
    @apply hover:bg-gradient-to-br hover:from-[#AC5DD9] hover:to-[#004FC4];
  }

  .hover-gradient-9 {
    @apply hover:bg-gradient-to-br hover:from-[#5B8DEF] hover:to-[#0063F7];
  }

  .hover-anime {
    @apply transition ease-in-out duration-700;
  }
}

.disable-scroll-bounce {
  overscroll-behavior-y: none;
}

.gm-style *:focus {
  outline: none !important;
  border: none !important;
}

.sidebar::-webkit-scrollbar {
  width: 0 !important;
}
.sidebar {
  overflow: -moz-scrollbars-none;
}
.sidebar {
  -ms-overflow-style: none;
}

.tbody:before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}

.styled-select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0.25rem;
}

/* CHECKBOX ROUNDED */
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"] + label {
  background-color: #ffffff00;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

#map_sual {
  border: 0 !important;
}

#map_sual .gm-style > div {
  border: 0 !important;
}

#map_sual .gm-style-iw-t {
  bottom: 50px !important;
}
#map_sual .gm-style .gm-style-iw-c {
  padding: 0;
}

#map_sual .gm-style-iw-d {
  overflow: auto !important;
}

#map_sual .gm-ui-hover-effect {
  display: none !important;
}

#map_sual .gm-style .gm-style-iw-tc::after {
  display: none;
}

.__range {
  margin: 30px 0 20px 0;
  -webkit-appearance: none;
  background-color: #e2e8f0;
  height: 3px;
  width: 100%;
  margin: 10px auto;
}
.__range:focus {
  outline: none;
}
.__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #3366ff;
  border-radius: 50%;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.rc-slider-handle:active {
  box-shadow: none !important;
}

.rc-slider-mark {
  top: 1.563rem !important;
}

/* input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* thumb carousel */

.carousel .slide .thumbnail,
.carousel .slide.previous .thumbnail {
  border-radius: 12px !important;
  width: 100% !important;
  height: 246px !important;
  object-fit: cover !important;
}

.carousel .thumb {
  transition: border 0.55s ease-in;
  display: inline-block;
  margin-right: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  border: 0 !important;
  padding: 0 !important;
}

.carousel .thumb .thumbnail {
  border-radius: 6px !important;
  border: 1.5px solid #3366ff00 !important;
  outline: 1px solid #3366ff00 !important;
  outline-offset: -1px;
  opacity: 0.6;
  width: 100% !important;
  height: 40px !important;
  object-fit: cover !important;
}

.carousel .thumb.selected .thumbnail,
.carousel .thumb:hover .thumbnail {
  border: 1.5px solid #3366ff !important;
  outline: 1px solid #3366ff !important;
  outline-offset: -2px;
  opacity: 1;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0 !important;
  width: 100px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #a0aec0 !important;
  display: none;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #a0aec0 !important;
  display: none;
}

.carousel .thumbs-wrapper {
  margin-left: 5px !important;
  margin-right: 5px !important;
  overflow: auto;
}

.carousel .thumbs {
  text-align: center !important;
}

/* calendar */

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* datepicker */
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -20px !important;
}

.react-datepicker__navigation .react-datepicker__navigation--next {
  right: 2px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 2px !important;
}

.with-time .react-datepicker {
  width: 328px !important;
}

.reactour__helper {
  padding: 17px 20px !important;
}

span[data-tour-elem="badge"] {
  background: #3366ff;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0.25rem 0.375rem;
  height: auto;
  border-radius: 100%;
}

.reactour__dot {
  width: 12px !important;
  height: 12px !important;
  border: 0 !important;
  background: #f0f5ff !important;
}

.reactour__dot--is-active {
  background: #3366ff !important;
  width: 27px !important;
  border-radius: 100px !important;
}

button[data-tour-elem="left-arrow"] {
  color: #1a202c !important;
}

button[data-tour-elem="left-arrow"]:disabled {
  color: #a0aec0 !important;
}

button[data-tour-elem="right-arrow"] {
  color: #1a202c !important;
}

button[data-tour-elem="right-arrow"]:disabled {
  color: #a0aec0 !important;
}
